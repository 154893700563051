import './blog.css';
import React from 'react';
import { Layout } from '../components/layout';
import { graphql, Link } from 'gatsby';
import PageSection from '../components/PageSection';
import SEO from '../components/seo';
import PageHeading from '../components/PageHeading';
import { HorizontalRule } from '../components/horizontalRule';

const Blog = ({ data: { allMarkdownRemark } }) => {
  const postList = allMarkdownRemark;

  return (
    <Layout>
      <SEO title="Blog" description="Fit Bodiz blog provides posts about fitness and news about Fit Bodiz" />
      <PageSection>
        <PageHeading title="Blog" heading="Fit Bodiz Blog" />
        <HorizontalRule />
        <div className="blog-post excerpt">
          {postList.edges.map(({ node }, i) => {
            const { date, author, image } = node.frontmatter;
            return (
              <Link to={node.fields.slug} className="link" key={i}>
                <div className="row justify-content-center">
                  <div className="col-3">
                    <img src={`/img/${image}`} className="img-fluid" />
                  </div>
                  <div className="col-9">
                    <h1 style={{ textAlign: 'left' }}>{node.frontmatter.title}</h1>
                    <h6>
                      Written by {author}, {date}
                    </h6>
                    <p>{node.excerpt}</p>
                  </div>
                </div>
                <hr />
              </Link>
            );
          })}
        </div>
      </PageSection>
    </Layout>
  );
};

export default Blog;

export const listQuery = graphql`
  query ListQuery {
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }, filter: { fields: { type: { eq: "blog" } } }) {
      edges {
        node {
          fields {
            slug
          }
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "Do MMMM YYYY")
            title
            description
            author
            image
          }
        }
      }
    }
  }
`;
